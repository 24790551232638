export default {
  data() {
    return {
      // elmentList: [
      //   {
      //     pcElementId: 452,
      //     phoneElementId: 458,
      //     descList: [
      //       {
      //         descName: "防爆四足机器人-X3 Stable",
      //         sort: 1,
      //       },
      //       {
      //         descName: "Ex IIB T4 Gb",
      //         sort: 2,
      //       },
      //       {
      //         descName: "IP66",
      //         sort: 3,
      //       },
      //       {
      //         descName: "0-1.0m/s",
      //         sort: 4,
      //       },
      //       {
      //         descName: "300mm",
      //         sort: 5,
      //       },
      //       {
      //         descName: "防爆等级",
      //         sort: 6,
      //       },
      //       {
      //         descName: "防护等级",
      //         sort: 7,
      //       },
      //       {
      //         descName: "行走速度",
      //         sort: 8,
      //       },
      //       {
      //         descName: "涉水深度",
      //         sort: 9,
      //       },
      //     ],
      //     type: "image",
      //     sort: 1,
      //   },
      //   {
      //     pcElementId: 453,
      //     phoneElementId: 459,
      //     descList: [
      //       {
      //         descName: "防爆四足机器人-X3 Stable",
      //         sort: 1,
      //       },
      //       {
      //         descName:
      //           "创新性地结合本安、隔爆及浇封等多种防爆设计方法，实现了防爆、轻量化、可靠性等综合性能加持。具备自适应学习、高精度图像采集、分析、感知等能力，对未知、非结构环境具有较高的适应性，能够实现在楼梯、台阶、缝隙、障碍物、狭小空间等复杂路面上运动，搭载500种以上智能算法，可广泛应用于大应急领域的各个行业。",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 2,
      //   },
      //   {
      //     pcElementId: 454,
      //     phoneElementId: 460,
      //     descList: [
      //       {
      //         descName: "应用场景",
      //         sort: 1,
      //       },
      //       {
      //         descName:
      //           "防爆四足机器人-X3 Stable可广泛应用于石油、化工、天然气、钢铁等高危行业实现无人化侦察、巡检等作业内容，可覆盖沙漠、森林、山地、戈壁等多元复杂应用场景。",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 3,
      //   },
      //   {
      //     pcElementId: "",
      //     phoneElementId: "",
      //     descList: [
      //       {
      //         descName: "基本功能",
      //         sort: 1,
      //       },
      //       {
      //         descName: "遥控器控制",
      //         sort: 2,
      //       },
      //       {
      //         descName: "热成像仪测温",
      //         sort: 3,
      //       },
      //       {
      //         descName: "气体检测",
      //         sort: 4,
      //       },
      //       {
      //         descName: "无人自主巡检",
      //         sort: 5,
      //       },
      //       {
      //         descName: "智能表计识别",
      //         sort: 6,
      //       },
      //       {
      //         descName: "泄漏检测",
      //         sort: 7,
      //       },
      //       {
      //         descName: "自身安全防护",
      //         sort: 8,
      //       },
      //     ],
      //     type: "image",
      //     sort: 4,
      //   },
      //   {
      //     pcElementId: 455,
      //     phoneElementId: 461,
      //     descList: [
      //       {
      //         descName: "防爆四足机器人-X3 Stable",
      //         sort: 0,
      //       },
      //       {
      //         descName: "规格参数",
      //         sort: 1,
      //       },
      //       {
      //         descName: "外形尺寸(长×宽×高)",
      //         sort: 2,
      //       },
      //       {
      //         descName: "防爆等级",
      //         sort: 3,
      //       },
      //       {
      //         descName: "自由度",
      //         sort: 4,
      //       },
      //       {
      //         descName: "爬坡能力",
      //         sort: 5,
      //       },
      //       {
      //         descName: "越障能力",
      //         sort: 6,
      //       },
      //       {
      //         descName: "涉水深度",
      //         sort: 7,
      //       },
      //       {
      //         descName: "主导航方式",
      //         sort: 8,
      //       },
      //       {
      //         descName: "整备质量",
      //         sort: 9,
      //       },
      //       {
      //         descName: "防护等级",
      //         sort: 10,
      //       },
      //       {
      //         descName: "行走速度",
      //         sort: 11,
      //       },
      //       {
      //         descName: "爬梯能力",
      //         sort: 12,
      //       },
      //       {
      //         descName: "跨越沟壑宽度",
      //         sort: 13,
      //       },
      //       {
      //         descName: "≤工况续航",
      //         sort: 14,
      //       },
      //       {
      //         descName: "重复定位精度",
      //         sort: 14,
      //       },
      //       {
      //         descName: "≤1200mm×600mm×800mm",
      //         sort: 15,
      //       },
      //       {
      //         descName: "Ex IIB T4 Gb",
      //         sort: 16,
      //       },
      //       {
      //         descName: "14（机器人 12+云台 2）",
      //         sort: 17,
      //       },
      //       {
      //         descName: "≤30",
      //         sort: 18,
      //       },
      //       {
      //         descName: "200mm",
      //         sort: 19,
      //       },
      //       {
      //         descName: "300mm",
      //         sort: 20,
      //       },
      //       {
      //         descName: "SLAM激光导航和SLAM激光定位",
      //         sort: 21,
      //       },
      //       {
      //         descName: "≤100kg",
      //         sort: 22,
      //       },
      //       {
      //         descName: "IP66",
      //         sort: 23,
      //       },
      //       {
      //         descName: "0 - 1.0m/s，速度可调",
      //         sort: 24,
      //       },
      //       {
      //         descName: "最大台阶高度 20cm，最小台阶宽度 25cm",
      //         sort: 25,
      //       },
      //       {
      //         descName: "300mm",
      //         sort: 26,
      //       },
      //       {
      //         descName: "≤3h",
      //         sort: 27,
      //       },
      //       {
      //         descName: "≤±5cm",
      //         sort: 28,
      //       },
      //     ],
      //     type: "image",
      //     sort: 5,
      //   },
      //   {
      //     pcElementId: 457,
      //     phoneElementId: 462,
      //     descList: [
      //       {
      //         descName: "防爆四足机器人-X3 Stable",
      //         sort: 1,
      //       },
      //       {
      //         descName: "防爆更轻量，智能更安全",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 6,
      //   },
      // ],
      elmentList: [],
      aboutswiper: null,
    };
  },
  mounted() {
    this.$store.dispatch("pageset/getbannerIndex", 0);
    document.documentElement.style.setProperty("--var-primary-color", "#fff");
    //          this.$store.dispatch("pageset/getSourceData", {
    //   createBy: "",
    //   updateBy: "",
    //   elmentList: this.elmentList,
    //   pageId: this.$route.query.pageId, //页面id
    //   pageName: this.$route.path,
    //   path: this.$route.path,
    //   remark: "",
    // });
    // 获取当前页面的配置信息
    this.$store
      .dispatch("pageset/getPageInfo", this.$route.query.pageId)
      .then(() => {
        this.elmentList = this.$store.state.pageset.sourceData.elmentList;
      });
  },
  methods: {
    retention() {
      this.$router.push("/reservation");
    },
    openPdf() {
      this.$router.push("/pdfView");
    },
  },
};

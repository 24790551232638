<template>
  <!-- 防爆四足机器人-X3 Stable-->
  <div class="product wheeledHighL4S" v-if="elmentList.length">
    <div class="banner_video" :id="'elm' + elmentList[0].id">
      <img
        v-if="this.$store.state.pageset.pcshow"
        class="video"
        :src="elmentList[0].pcImg"
        :ref="'video' + elmentList[0].pcElementId"
      />
      <img
        v-else
        class="video"
        :src="elmentList[0].phoneImg"
        :ref="'video' + elmentList[0].phoneElementId"
        :id="'videoOne'"
      />
      <div class="img-con">
        <div class="title">
          <h1>{{ elmentList[0].descList[0].descName }}</h1>
        </div>
      </div>
      <div class="dec" v-animate="'queue-bottom'">
        <div class="dec-par">
          <div class="dec-par-item" v-for="index in 4" :key="index">
            <p class="dec-par-item-title">
              {{ elmentList[0].descList[index].descName }}
            </p>
            <p class="dec-par-item-dec">
              {{ elmentList[0].descList[index + 4].descName }}
            </p>
          </div>
        </div>
        <div>
          <el-button
            size="small"
            style="margin-right: 20px"
            class="btn-div-experience btn-div-order"
            plain
            @click="retention"
            >Order Now</el-button
          >
          <el-button
            size="small"
            class="btn-div-experience btn-div-test"
            plain
            @click="retention"
            >Register for Demo</el-button
          >
        </div>
        <div class="scroll-wrap">
          <div class="remark">
            The product shown in the video on this page is for reference only. Due to factors such as production location, the appearance, configuration, and model details may vary. Please refer to the actual product delivered in your region for precise specifications.
          </div>
        </div>
      </div>
    </div>
    <div class="wheeledHighL4S-content">
      <div class="content-one" :id="'elm' + elmentList[1].id">
        <img
          :src="elmentList[1].pcImg"
          v-if="this.$store.state.pageset.pcshow"
        />
        <img :src="elmentList[1].phoneImg" v-else />
        <div class="dec gradient" v-animate="'queue-bottom'">
          <div class="dec-left">
            <p class="p1">{{ elmentList[1].descList[0].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience white-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
          <div class="dec-right">
            <p class="p2">
              {{ elmentList[1].descList[1].descName }}
            </p>
          </div>
          <div class="phone-order" v-if="!this.$store.state.pageset.pcshow">
            <el-button
              size="small"
              class="btn-div-experience white-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
        </div>
      </div>

      <img :src="elmentList[2].pcImg" v-if="this.$store.state.pageset.pcshow" />
      <img :src="elmentList[2].phoneImg" v-else />
      <div class="content-one" :id="'elm' + elmentList[2].id">
        <div
          class="dec"
          v-animate="'queue-bottom'"
          style="background-color: white; color: black !important"
        >
          <div class="dec-left">
            <p class="p1">{{ elmentList[2].descList[0].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
          <div class="dec-right">
            <p class="p2">
              {{ elmentList[2].descList[1].descName }}
            </p>
          </div>
          <div class="phone-order" v-if="!this.$store.state.pageset.pcshow">
            <el-button
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
        </div>
      </div>

      <!-- 新增基本功能和选配功能模块 -->
      <div class="basic-function" :id="'elm' + elmentList[2].id">
        <p class="p-title">{{ elmentList[3].descList[0].descName }}</p>
        <div class="basic-con" v-if="$store.state.pageset.pcshow">
          <div class="con-item">
            <p v-for="index in 2" :key="index">
              {{ elmentList[3].descList[index].descName }}
            </p>
          </div>
          <div class="con-item">
            <p v-for="index in 2" :key="index">
              {{ elmentList[3].descList[index + 2].descName }}
            </p>
          </div>
          <div class="con-item">
            <p v-for="index in 2" :key="index">
              {{ elmentList[3].descList[index + 4].descName }}
            </p>
          </div>
          <div class="con-item">
            <p v-for="index in 1" :key="index">
              {{ elmentList[3].descList[index + 6].descName }}
            </p>
          </div>
        </div>
        <div class="basic-con" v-else>
          <div class="con-item">
            <p v-for="index in 4" :key="index">
              {{ elmentList[3].descList[index].descName }}
            </p>
          </div>
          <div class="con-item">
            <p v-for="index in 3" :key="index">
              {{ elmentList[3].descList[index + 4].descName }}
            </p>
          </div>
        </div>
      </div>

      <!-- 产品参数(四足参数单独样式) -->
      <div class="content-par-remark" :id="'elm' + elmentList[4].id">
        <div class="img">
          <img :src="elmentList[4].pcImg" v-if="$store.state.pageset.pcshow" />
          <img :src="elmentList[4].phoneImg" v-else />
        </div>
        <div class="dec">
          <p class="p1">{{ elmentList[4].descList[0].descName }}</p>
          <p class="p1">{{ elmentList[4].descList[1].descName }}</p>
          <div class="dec-con" v-animate="'queue-bottom'">
            <div class="dec-con-left">
              <ul class="des-ui">
                <li v-for="index in 7" :key="index">
                  <p>{{ elmentList[4].descList[index + 1].descName }}</p>
                  <p>{{ elmentList[4].descList[index + 15].descName }}</p>
                </li>
              </ul>
            </div>
            <div class="dec-con-right">
              <ul class="des-ui">
                <li v-for="index in 7" :key="index">
                  <p>{{ elmentList[4].descList[index + 8].descName }}</p>
                  <p>{{ elmentList[4].descList[index + 22].descName }}</p>
                </li>
              </ul>
            </div>
          </div>
          <el-button
            v-animate="'queue-bottom'"
            size="small"
            class="btn-div-experience white-btn"
            plain
            @click="openPdf"
            >Product Manual</el-button
          >
        </div>
      </div>

      <!-- 产品订购(四足参数单独样式) -->
      <div class="content-par-order" :id="'elm' + elmentList[5].id">
        <div class="img" v-if="!this.$store.state.pageset.pcshow">
          <img :src="elmentList[5].phoneImg" />
        </div>
        <div class="dec" v-animate="'queue-bottom'">
          <p>{{ elmentList[5].descList[0].descName }}</p>
          <p>{{ elmentList[5].descList[1].descName }}</p>
          <div>
            <el-button
              size="small"
              style="background-color: black; color: #fff"
              class="btn-div-experience"
              plain
              @click="retention"
              >Order Now</el-button
            >
            <el-button
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Register for Demo</el-button
            >
          </div>
        </div>
        <div class="img" v-if="this.$store.state.pageset.pcshow">
          <img :src="elmentList[5].pcImg" />
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import quadrupedX3Stable from "./quadrupedX3Stable_en.js";

export default quadrupedX3Stable;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "../../common.scss";
@import "../quadrupedX3Stable.scss";
</style>
